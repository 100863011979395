/*
 * CONTENS Facet Setting Widget
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 */

import './jquery.cms.facetwidget';

(function($) {
	$.widget("cms.cFacetSettingWidget", $.cms.cFacetWidget, {

		options: {
			type: "radio"
		},

		_preCreate: function() {
			this.element.addClass("fctFilter");
			var allItem = {};
			allItem.id = this.options.allid;
			allItem.displayName = this.options.allText;
			allItem.name = this.options.allText;
			allItem.selected = true;
			this.options.items.splice(0, 0, allItem);
		},

		_htmlCreated: function() {
			this.setVal("");

			var self = this,
				inputEl, options;

			switch (this.options.type) {
				case 'radio':
					$('.con-facet-item', this.element).click(function(e) {
						var checkbox = $("input", this)[0];

						if (checkbox.checked === true) {
							return;
						}

						checkbox.checked = !checkbox.checked;
						self._handleItemClick(e);
						return false;
					});

					$(".con-facet-item input", this.element).click(function(e) {
						self._handleItemClick(e);
						e.stopPropagation();
					});
					break;
				case 'datepicker':
					options = {
						timeInput: true,
						autosize: true,
						dateFormat: window.cms.i18n.system.text.dateformat_short,
						changeMonth: true,
						changeYear: true
					};
					inputEl = $('#fctSt_' + this.options.objectid + '_from');
					inputEl.datepicker(options);
					inputEl = $('#fctSt_' + this.options.objectid + '_to');
					inputEl.datepicker(options);

					this.element.on('change', '.con-facet-item', $.proxy(function(e) {
						var el = $(e.currentTarget).find('.ui-form-row-input-main');

						if (el.attr("selid") !== 'custom') {
							// selected one of the predefined dates - ensure custom is hidden and the datepicker fields are empty
							$('#' + this.options.objectid + '_custom', this.element).hide();
							$('.hasDatepicker', this.element).val("");
							this._handleItemClick(e);
							if ($(".error-" + this.options.objectid).css("display") == "block") {
								$(".error-" + this.options.objectid).css("display", "none");
							}
						} else {
							// selected the custom date filter
							this.selId = 'custom';
							$('#' + this.options.objectid + '_custom', this.element).show();
							$('#' + this.options.objectid + '_custom')[0].scrollIntoView({
								behavior: "smooth",
								block: "nearest"
							});
						}
					}, this));

					$('.hasDatepicker', this.element).change(function() {
						var dt,
							start = "",
							end = "",
							name = this.id.substr(0, this.id.length),
							id = this.id.substr(this.id.length - 1),
							parts,
							errorField;

						const date_inp = '#' + name.split("_", name.split("_").length - 1).join("_");
						let from = moment($(date_inp + '_from').val(), window.cms.cBaseApp.options.currentUser.dateformat.toUpperCase());
						let to = moment($(date_inp + '_to').val(), window.cms.cBaseApp.options.currentUser.dateformat.toUpperCase());

						// check the dates after a change event and perform validation prior to handleItemClick()
						if (id === "0") {
							dt = moment(this.value, window.cms.cBaseApp.options.currentUser.dateformat.toUpperCase());
							if (dt._isValid) {
								start = dt.format(window.cms.cBaseApp.options.currentUser.dateformat.toUpperCase());
								this.value = start;
							} else {
								this.value = "";
								start = this.value;
							}
							end = $('#' + name + "1").val() || "";
						} else {
							dt = moment(this.value, window.cms.cBaseApp.options.currentUser.dateformat.toUpperCase());
							if (dt._isValid) {
								end = dt.format(window.cms.cBaseApp.options.currentUser.dateformat.toUpperCase());
								this.value = end;
							} else {
								this.value = "";
								end = "";
							}
							start = $('#' + name + "0").val() || "";
						}

						// date field name e.g. "fctSt_dateupdated_from" or "fctSt_ips_datecreated_to"
						parts = name.split('_');

						if (parts.length == 2) {
							errorField = parts[1];
						} else {
							errorField = parts.slice(1, -1).join('_');
						}

						if (start.length || end.length) {
							// trigger warning if end date is older than start date
							if (to < from) {
								if ($(".error-" + errorField).css("display") == "none") {
									$(".error-" + errorField).css("display", "block");
								}
							} else {
								if ($(".error-" + errorField).css("display") == "block") {
									$(".error-" + errorField).css("display", "none");
								}
							}
						}
						// clear warning if either field is empty
						else {
							if ($(".error-" + errorField).css("display") == "block") {
								$(".error-" + errorField).css("display", "none");
							}
						}

						// if either of the dates have been filled or changed then trigger a search
						self.element.trigger("facet.changed");

						return false;
					});
					break;
				case "select":
					var oSelect2 = $('#fctSt_' + this.options.objectid + '_' + this.options.items[0].id),
						input = $(oSelect2, this.element);

					if (typeof this.options.callback === "function") {
						this.options.callback.apply(this, this.element);
					}

					oSelect2.on("select2:select select2:unselect", function() {
						// hack to ensure dropdown is hidden when the searchform reloads/redraws after a search
						window.setTimeout(function() {
							input.select2('close');
						}, 0);
						self._handleItemClick();
					});

					break;
			}
		},

		JSONData: function() {
			var infoObj = {},
				resultDate, filterDate, resultMonth, dateString;

			infoObj.selected = [];
			switch (this.options.type) {
				case "radio":
					if (this.options.allid.toString() !== this.selId) {
						infoObj.id = this.options.objectid;
					}
					if (this.options.allid.toString() !== this.selId) {
						if (this.options.useDate === true) {
							resultDate = new Date();
							filterDate = Number(this.selId) * (1000 * 60 * 60 * 24);
							resultDate = new Date(resultDate.getTime() - filterDate);
							resultMonth = (resultDate.getMonth() + 1).toString();
							if (resultMonth.length < 2) {
								resultMonth = "0" + resultMonth;
							}
							resultMonth = (resultDate.getMonth() + 1).toString();
							if (resultMonth.length < 2) {
								resultMonth = "0" + resultMonth;
							}
							dateString = resultDate.getFullYear() + "-" + resultMonth + "-";
							resultMonth = resultDate.getDate().toString();
							if (resultMonth.length < 2) {
								resultMonth = "0" + resultMonth;
							}
							dateString += resultMonth;
							infoObj.selected.push(dateString);
						} else {
							infoObj.selected.push(this.selId);
						}
					}
					break;
				case "datepicker":
					var startDate, endDate, fromEl = $('#fctSt_' + this.options.objectid + '_from'),
						toEl = $('#fctSt_' + this.options.objectid + '_to'),
						obj;
					infoObj = [];

					if (this.selId === "") {
						// remove the filter
						break;
					}
					if (this.selId !== "custom") {
						// create a relative filter
						obj = {};
						obj.id = this.options.objectid;
						obj.selected = [];
						obj.selected.push(moment().subtract(1, this.selId).format(window.cms.cBaseApp.options.currentUser.dateformat.toUpperCase()));
						infoObj.push(obj);
					} else {
						// extract the to and from dates
						if (fromEl.val().length) {
							obj = {};
							obj.id = this.options.objectid;
							startDate = moment(fromEl.val(), window.cms.cBaseApp.options.currentUser.dateformat.toUpperCase());
							obj.selected = [];
							obj.selected.push(startDate.format(window.cms.cBaseApp.options.currentUser.dateformat.toUpperCase()));
							infoObj.push(obj);
						}
						if (toEl.val().length) {
							obj = {};
							obj.id = this.options.objectid + "_to";
							endDate = moment(toEl.val(), window.cms.cBaseApp.options.currentUser.dateformat.toUpperCase());
							obj.selected = [];
							obj.selected.push(endDate.format(window.cms.cBaseApp.options.currentUser.dateformat.toUpperCase()));
							infoObj.push(obj);
						}
					}
					break;
				case "select":
					var el = $('#fctSt_' + this.options.objectid + '_0'),
						data;
					infoObj.id = this.options.objectid;
					// check if select2 is on the object
					if (el.data('select2')) {
						// select 2 is defined
						data = el.select2('data');
						for (var item in data) {
							infoObj.selected.push(data[item].text);
						}
					} else {
						// no select 2 need to get the selected elements
						data = el.find(':selected');
						$.each(data, function(item, row) {
							infoObj.selected.push(row.value);
						});
					}
					break;
			}
			return infoObj;
		},

		setVal: function(nval) {
			var input, opts, option, x;

			switch (this.options.type) {
				case "radio":
					if (this.selId === nval) {
						return;
					}

					input = $('input[type="radio"]:checked', this.element);
					if (input.length > 0) {
						input[0].checked = false;
					}

					input = $('input[selid="' + nval + '"]', this.element);
					if (input.length < 1) {
						input = $('input[type="radio"]', this.element);
					}
					input[0].checked = true;
					this.selId = input.first().attr("selid");
					break;
				case "select":
					// hack to ensure dropdown is hidden when the searchform reloads/redraws after a search
					input = $('#fctSt_' + this.options.objectid + '_0', this.element);
					if (nval !== "") {

						opts = nval.split(',');

						for (x = 0; x < opts.length; ++x) {
							if (!input.find("option[value='" + opts[x] + "']").length) {
								option = new Option(opts[x], opts[x], true, true);
								input.append(option);
							}
						}

						input.trigger('change');
					}
					window.setTimeout(function() {
						input.select2('close');
					}, 0);

					break;
				case "datepicker":

					if (nval && (nval instanceof Object)) {
						var dataVal = nval[this.options.objectid];
						/*
						 don't modify the selected value if the returned value is the same or,
						  if the selected value is set to "custom"
						*/
						if (this.selId === dataVal || this.selId === "custom") {
							return;
						}
						if (dataVal) {
							try {
								if (moment().diff(moment(dataVal, window.cms.cBaseApp.options.currentUser.dateformat.toUpperCase()), 'days') === 1) {
									dataVal = 'days';
								} else if (moment().diff(moment(dataVal, window.cms.cBaseApp.options.currentUser.dateformat.toUpperCase()), 'weeks') === 1) {
									dataVal = 'weeks';
								} else if (moment().diff(moment(dataVal, window.cms.cBaseApp.options.currentUser.dateformat.toUpperCase()), 'months') === 1) {
									dataVal = 'months';
								} else {
									dataVal = 'custom';
									$('#' + this.options.objectid + '_custom', this.element).show();
									$('#fctSt_' + this.options.objectid + '_from', this.element).val(nval[this.options.objectid] || "");
									$('#fctSt_' + this.options.objectid + '_to', this.element).val(nval[this.options.objectid + "_to"] || "");
								}
							} catch (e) {
								$.noop();
							}
						}
					}
					input = $('input[type="radio"]:checked', this.element);
					if (input.length > 0) {
						input[0].checked = false;
					}

					input = $('input[selid="' + dataVal + '"]', this.element);
					if (input.length < 1) {
						input = $('input[type="radio"]', this.element);
					}
					input[0].checked = true;
					this.selId = input.first().attr("selid");
					break;
				default:
					this.selId = "";

			}
		},

		_valChanged: function(sType) {
			if (sType === "radio") {
				return this.selId !== $('input[type="radio"]:checked', this.element).attr("selid");
			} else {
				return true;
			}
		},

		_handleChange: function() {

			switch (this.options.type) {

				case "radio":
					if (this._valChanged("radio")) {
						this.selId = $('input[type="radio"]:checked', this.element).attr("selid");
						this.element.trigger("facet.changed");
					}
					break;
				case "datepicker":
					if (this._valChanged("radio")) {
						this.selId = $('input[type="radio"]:checked', this.element).attr("selid");
						if (this.selId !== "custom") {
							this.element.trigger("facet.changed");
						}
					}
					break;
				default:
					this.element.trigger("facet.changed");
			}
		},

		_fixItem: function(item) {
			var itemName, itemClass = "",
				itemTitle = "";

			itemName = item.name;
			if (itemName === undefined) {
				itemName = "Undefined";
			}

			item.displayName = itemName;
			item.htmlClass = itemClass;
			item.styleExtra = itemTitle;

			item.name = this.options.translate(item.id);
			item.selected = false;
			if (typeof item.count !== "string") {
				return;
			}
			if (typeof item.id === "string") {
				item.id = Number(item.id);
			}
			if (typeof item.count === "string") {
				item.count = Number(item.count);
			}
		},

		reset: function() {
			var firstElement = true,
				input,
				self = this;

			if (this.options.type === "radio") {
				input = $("input[type='radio']", this.body);
				$(input).each(function() {
					this.checked = firstElement;
					if (firstElement === true) {
						self.selId = $(this).attr("selid");
						firstElement = false;
					}
				});
			} else {
				input = $(".hasDatepicker", this.body);
				$(input).each(function() {
					this.value = "";
				});
				input = $("input[type='radio']", this.body);
				$(input).each(function() {
					this.checked = firstElement;
					if (firstElement === true) {
						self.selId = $(this).attr("selid");
						firstElement = false;
					}
				});

			}
		},

		_getSelectArray: function() {
			var result = [],
				input = $("input[type='radio']", this.body);

			$(input).each(function() {
				if (this.checked === true) {
					result.push($(this).attr("selid"));
				}
			});
			return result;
		}

	});

}(jQuery));
