/*
 * CONTENS cRowtype
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 * Events fired (all in between the event context *.rowtype):
 *  - change( valueold, valuenew )
 *  - click
 *  - blur
 *  - mouseenter
 *  - mouseleave
 *  - register
 *  - getlabel( [rows] )
 *  - communicate( [rows], data )
 *  - get
 *  - set
 *  :for multiusage
 *  - add
 *  - remove
 *  - sort
 *
 *  Events received:
 *  - changelanguage( oldlang, newlang )
 *  - translationview( activate, currentlang, newlang )
 *  - reset
 *  - copy
 *  - load
 *  - validate
 *  - aftersubmit
 *  - transferlanguage( currentlang, newlang )
 *  - setstatus
 *  - resetstatus
 *  - setvalidation
 *  - setfocus
 *  - hide( activate )
 *  - sendlabel( [rows], label )
 *  - hideerrors
 */
(function($, window) {

	var hoverClass = " ui-form-row-hover",
		mainInput = "ui-form-row-input-main",
		attrInput = "ui-form-row-input-attr",
		multi_wrp = "ui-form-row-multi",
		lang_wrp = "ui-form-row-language",
		errorClass = "error";

	$.widget("cms.cRowtype", {
		/* widget settings and default options */
		options: {
			name: '',
			type: '',
			valuepath: '',
			furtherValuepaths: null,
			multilanguage: false,
			multiusage: false,
			initializedlanguages: [],
			guilang: 1,
			validation: {
				required: false
			},
			setup: {
				hasAttributeInputs: false,
				ismultipleusage: null,
				dtTabNumbering: false
			},
			modificators: {
				init_hideLangWrp: function(ilang, iCurrLang) {
					var il = parseInt(ilang, 10);
					return (il !== 0 && il !== iCurrLang);
				},
				getMainInputs_lang: null,
				getMainInputs_index: null,
				getValues_lang: null,
				addRow_newRow: null,
				addRow_templateData: null,
				addRow_SortOrder: null,
				addBtnOverride: null,
				rowModel: null
			},
			onSubmit: null,
			i18n: {},
			showInlineMultilang: true
		},

		widgetStoredReferences: {},

		widgetEventPrefix: 'cms-rowtype-',

		widgetBaseClass: 'ui-cms-rowtype',

		/* standard widget functions */
		_create: function _create() {
			var skeyFVp, elFormPage, oForm, oFormWrapper;

			this.initDone = false;
			this.element.trigger('beforeInit.rowtype', this);

			// load widget plugins
			this._plugin($.cms.extensions.i18noptions);
			this._plugin($.cms.extensions.validator);
			this._plugin($.cms.extensions.executionQueue);

			// define statics
			this.form = this._getForm();
			this.options.form = this.form;
			if (this.form.data('cms-cForm')) {
				this.isValueUppercase = this.form.cForm('option', 'isValueUppercase');
				this.options.guilang = this.form.cForm('option', 'guilang');
				oForm = $.data(this.form[0], 'cms-cForm');
			}

			if (this.options.furtherValuepaths) {
				this.options.setup.hasAttributeInputs = true;
			}

			// get language data
			this.language = 0;
			this.languages = [0];
			this.contentLanguages = [0];

			if (this.options.multilanguage) {
				if (this.form.data('cms-cForm')) {
					this.language = this.form.cForm('getLanguage');
					this.languages = this.form.cForm('getLanguages');
					this.contentLanguages = this.form.cForm('getContentLanguages');
				}
				this.multilanguage = {};
				this.multilanguage.elModel = this.element.find('.js-multilang-model');
				this.multilanguage.sModel = this.multilanguage.elModel.html();
				this.multilanguage.elModel.remove();
			}

			this.languageTranslation = null;

			// determine if we are using the multilang edit feature
			if (oForm) {
				this.oForm = oForm;
				oFormWrapper = oForm.getWrapper();
				if (oForm.options.monitorlangchange === 0 && this.languages.length > 1 && this.options.showInlineMultilang === true) {
					this.showMultilangs = true;
				} else {
					this.showMultilangs = false;
				}
				// need to track for the entire form if the languagefields are open
				this.oForm.multilangsOpen = 0;
			}

			this.multilangsOpen = false;
			this.usedfakelanguages = false;

			// special case for rowtypes in instances dialog
			if (oFormWrapper && oFormWrapper[0] && oFormWrapper[0].id.indexOf("instances") >= 0) {
				this.usedfakelanguages = true;
			}

			// define empty inputs-cache container
			this.oInputs = {
				wrp: {},
				inp: {},
				langWrp: {}
			};
			if (this.options.setup.hasAttributeInputs) {
				this.oInputs.attr = {};
			}

			// try to cache the form-page-container
			elFormPage = this.element.closest('.section');

			if (elFormPage) {
				this.formpage = elFormPage.attr('data-sectionid');
			} else {
				this.formpage = null;
			}
			/* certain options MUST be lowercase dont take any chances */
			this.options.valuepath = this.options.valuepath.toLowerCase();
			this.options.furtherValuepaths = $.convertToLowerCase(this.options.furtherValuepaths);

			// define statics
			this.name = this.options.name.toLowerCase();
			this.type = this.options.type;

			// define internal selectors
			this._selectors = {
				mainInput: mainInput,
				attrInput: attrInput,
				multi_wrp: multi_wrp,
				lang_wrp: lang_wrp,
				errorClass: errorClass
			};

			// read all HTML available main Inputs.
			this._getMainInputs();

			// bindings for rowtype
			this.element.on({
				// native events
				'click': $.proxy(this._handleMouseClick, this),
				'mouseenter': $.proxy(this._handleMouseEnter, this),
				'mouseleave': $.proxy(this._handleMouseLeave, this),
				// custom events
				'focus.rowtype': $.proxy(this._handleRowFocus, this),
				'load.rowtype': $.proxy(this._handleRowLoad, this),
				'copy.rowtype': $.proxy(this._handleRowOnCopy, this),
				'duplicate.rowtype': $.proxy(this._handleRowCopy, this),
				'hide.rowtype': $.proxy(this._handleRowHide, this),
				'hideerrors.rowtype': $.proxy(this._handleRowHideErrors, this),
				'reset.rowtype': $.proxy(this._handleRowReset, this),
				'clear.rowtype': $.proxy(this._handleRowClear, this),
				'validate.rowtype': $.proxy(this._handleRowValidate, this),
				'setstatus.rowtype': $.proxy(this._handleRowSetStatus, this),
				'setvalidation.rowtype': $.proxy(this._handleRowSetValidation, this),
				'changelanguage.rowtype': $.proxy(this._handleRowChangeLanguage, this),
				'changelanguagedirection.rowtype': $.proxy(this._handleRowChangeLanguageDirection, this),
				'sendlabel.rowtype': $.proxy(this._handleRowSendlabel, this),
				'translationview.rowtype': $.proxy(this._handleRowTranslationView, this),
				'form.translate': $.proxy(this._handleFormTranslation, this),
				'can.copy': $.proxy(this._handleCanCopy, this),
				'transferlanguage.rowtype': $.proxy(this._handleRowTransferLanguage, this),
				'aftersubmit.rowtype': $.proxy(this._handleRowAfterSubmit, this),
				'communicate.rowtype': $.proxy(this._handleRowCommunicate, this),
				'removeRow.rowtype': $.proxy(this._handleRemoveRow, this),
				'rebindInputs.rowtype': $.proxy(this._handleRebindInputs, this),
				'setValueByField.rowtype': $.proxy(this._handleSetValueByField, this)
			});

			// define the valuepaths
			this.expectedValueTypes = ['main'];
			if (this.options.furtherValuepaths !== null) {
				for (skeyFVp in this.options.furtherValuepaths) {
					if (this.options.furtherValuepaths.hasOwnProperty(skeyFVp)) {
						this.expectedValueTypes.push(skeyFVp);
					}
				}
			}
			this.expectedValueTypesCount = this.expectedValueTypes.length;

			this.initValue = {};
			this.element.trigger('getValueByField.form', ['main', this.options.valuepath, (this.options.multilanguage ? _.union(this.languages, this.contentLanguages) : [0]), this.name]);

			// define internal further valuepath settings and get the init data from the form
			this.initValueSettings = {};
			if (this.options.furtherValuepaths !== null) {
				for (skeyFVp in this.options.furtherValuepaths) {
					if (this.options.furtherValuepaths.hasOwnProperty(skeyFVp)) {
						this.initValueSettings[skeyFVp] = $.extend({
							path: '',
							multilanguage: this.options.multilanguage,
							multiusage: this.options.multiusage
						}, this.options.furtherValuepaths[skeyFVp]);

						this.element.trigger('getValueByField.form', [skeyFVp, this.options.furtherValuepaths[skeyFVp].path, (this.options.multilanguage ? this.languages : [0]), skeyFVp]);
					}
				}
			}

			// add the this scope to the element to get the rowtype via $().data()
			$.data(this.element[0], 'rowtype', this);

			// fire register to register to form
			this.element.trigger('register.rowtype', [this.name]);

			// if multiusage is defined init the widget.plugin
			if (this.options.multiusage) {
				this._plugin($.cms.extensions.multiusage);
			}

			/*
				Helper function to evalute dot path. Helps avoid complicated if statements
			*/
			function _getObj(root, path) {
				var rt = root,
					i, pths;
				pths = path.split('.');
				for (i = 0; i < pths.length && rt; i++) {
					rt = rt[pths[i]];
				}
				return rt;
			}

			this.options._defaults = _getObj(this, 'oForm.options.data._meta.settings.' + this.name);
			if (this.options._defaults && typeof this.options._defaults.hasselectactioncodenameright !== 'undefined' && this.options._defaults.hasselectactioncodenameright === false) {
				$(this.element).addClass('disabled');
			}
		},

		_init: function _init() {
			var ilang = null,
				oForm = $.data(this.form[0], 'cms-cForm'),
				transicon,
				lw = $('.ui-form-row-languagewrp', this.element);

			if (oForm !== undefined) {
				this.options.editorLanguages = oForm.options.editorLanguages.slice();
				this.options.initializedlanguages = oForm.options.initializedlanguages.slice();
				this.initDone = true;

				if (oForm.options.monitorlangchange) {
					// dataclasses forms (monitorlangchange > 0) only current languages to be initialized
					this._addToQueue('initRowtype', this._initLanguage, this, [this.language]);
				} else {
					// admin forms (monitorlangchange === 0) require all languages to be initialized
					if (this.contentLanguages.length > 1) {
						// remove lang_id zero when multi-language rowtype
						this.contentLanguages = $.removeInArray(0, this.contentLanguages);
					}
					this._addToQueue('initRowtype', this._initLanguage, this, [this.contentLanguages]);
				}
			} else {
				this._addToQueue('initRowtype', this._initLanguage, this, [this.languages]);
			}

			this._addToQueue('initRowtype', this._bindInput, this);
			this._runQueue('initRowtype');
			this.element.trigger('afterInit.rowtype');

			// select the current lang and hide all others (not in dataclasses)
			if (oForm && oForm.options.monitorlangchange === 0) {
				for (ilang in this.oInputs.langWrp) {
					if (this.oInputs.langWrp.hasOwnProperty(ilang) && this.options.modificators && this.options.modificators.init_hideLangWrp.apply(this, [ilang, this.form.cForm('getLanguage')])) {
						this.oInputs.langWrp[ilang].hide();
					}
				}
			}

			// add translate icon and it's events
			if (this.showMultilangs) {
				this.langwrap = lw;
				this.langwrapHeight = this.langwrap.height();
				transicon = this.element.find('.ui-form-row-langoverall');
				transicon.html('<a href="javascript:void(0)">' + window.cms.i18n.system.text.languages_show + '</a>');
				transicon.on("click", $.proxy(this._toggleMultilangs, this));
				this.transicon = transicon;
				transicon.show();
			} else if (this.oForm && this.oForm.options.languages.length > 1 && !this.usedfakelanguages) {
				transicon = this.element.find('.ui-form-row-langoverall');
				transicon.html(window.cms.i18n.system.text.languages_multi);
				this.transicon = transicon;
			} else if (this.usedfakelanguages) {
				transicon = this.element.find('.ui-form-row-langoverall');
				transicon.hide();
			}
		},
		_toggleMultilangs: function _toggleMultilangs() {
			var ilang, nhght, self = this,
				frmWrap = this.form.closest('.js-cms-formwrapper');

			// hide fields in other languages
			if (this.multilangsOpen) {
				nhght = 0;
				for (ilang in this.oInputs.langWrp) {
					if (parseInt(ilang, 10) !== this.language) {
						this.oInputs.langWrp[ilang].hide("fast");
					} else {
						nhght += this.oInputs.langWrp[ilang].outerHeight(true);
					}
				}
				this.langwrap.animate({
					"height": nhght + "px"
				}, "fast", $.proxy(function() {
					this.langwrap[0].style.height = null;
				}, this));
				$('.ui-form-row-textline-language', this.element).hide("fast");
				this.langwrap.removeClass('languagewrpInline');
				this.transicon.html('<a href="javascript:void(0)">' + window.cms.i18n.system.text.languages_show + '</a>');
				this.oForm.multilangsOpen++;
				// show fields in other languages
			} else {
				nhght = 28;
				$('.ui-form-row-language', this.element).each(function() {
					nhght += $(this).outerHeight(true);
					nhght += $('.ui-form-row-textline-language', this).outerHeight(true);
				});
				this.langwrap.addClass('languagewrpInline');
				this.langwrap.animate({
					"height": nhght + "px"
				}, "fast", $.proxy(function() {
					this.langwrap[0].style.height = null;
				}, this));
				$('.ui-form-row-language', this.element).filter(function() {
					return (self.options.editorLanguages.indexOf(parseInt($(this).attr('rel'), 10)) > -1);
				}).show();
				$('.ui-form-row-textline-language', this.element).show();

				// set focus on first textline and textarea
				$($('.ui-form-row-textline-language', this.element).siblings('ul')[0]).find('input,textarea').focus();

				// set focus on first richtext
				$($('.ui-form-row-textline-language', this.element).siblings('ul')[0]).find($('.cke_wysiwyg_frame')).contents().find('.cke_editable').focus();

				this.transicon.html('<a href="javascript:void(0)">' + window.cms.i18n.system.text.languages_hide + '</a>');
				this.oForm.multilangsOpen--;
			}

			this.multilangsOpen = !this.multilangsOpen;

			if (this.oForm.multilangsOpen === 0) {
				// show lang switcher and translation button
				$('.js-translationselect', frmWrap).show();
				$('.js-translationlangswitch', frmWrap).show();
			} else {
				// hide lang switcher and translation button
				$('.js-translationselect', frmWrap).hide();
				$('.js-translationlangswitch', frmWrap).hide();
			}
		},
		widget: function widget() {
			return this.element;
		},
		destroy: function destroy() {
			// trigger destroy to handle is in the space of the rowtypes
			this.element.trigger('beforeRowDestroy');
			this.element.removeData();

			this.element.off(
				'mouseenter ' +
				'mouseleave ' +
				'focus.rowtype ' +
				'load.rowtype ' +
				'copy.rowtype ' +
				'hide.rowtype ' +
				'hideerrors.rowtype ' +
				'reset.rowtype ' +
				'validate.rowtype ' +
				'setstatus.rowtype ' +
				'setvalidation.rowtype ' +
				'sendlabel.rowtype ' +
				'translationview.rowtype ' +
				'changelanguage.rowtype ' +
				'transferlanguage.rowtype ' +
				'aftersubmit.rowtype ' +
				'communicate.rowtype ' +
				'clear.rowtype ' +
				'click ' +
				'removeRow.rowtype ' +
				'setValueByField.rowtype');

			this._unbindInput();

			this.element.off();

			if (this.__destroy) {
				this.__destroy();
			}
			$.Widget.prototype.destroy.call(this);
		},
		_setOption: function _setOption(key, value) {
			switch (key) {
				case "hidden":
					if (value === true) {
						this.element.hide();
					} else {
						this.element.show();
					}
					break;
				case "readonly":
					if (value === true) {
						/* call the readonly method for the rowtype*/
						this._setReadOnly();
					}
					break;
				case "setstatus":
					this.setStatus(value);
					break;
			}

			$.Widget.prototype._setOption.apply(this, arguments);
		},

		/* Event handling functions */
		// rowtype handlers
		_handleAddRow: function _handleAddRow() {
			$.noop();
		},

		_handleMouseClick: function _handleMouseClick() {
			this.element.trigger("click.rowtype");
		},
		_handleMouseEnter: function _handleMouseEnter() {
			this.highlight(true);
			this.element.trigger("mouseenter.rowtype");
		},
		_handleMouseLeave: function _handleMouseLeave() {
			this.highlight(false);
			this.element.trigger("mouseleave.rowtype");
		},
		_handleRowFocus: function _handleRowFocus() {
			$.noop();
		},
		_handleRowLoad: function _handleRowLoad() {
			$.noop();
		},
		_handleRowOnCopy: function _handleRowOnCopy() {
			$.noop();
		},
		_handleRowCopy: function _handleRowCopy() {
			$.noop();
		},
		_handleRowHide: function _handleRowHide() {
			$.noop();
		},
		_handleRowHideErrors: function _handleRowHideErrors() {
			$.noop();
		},
		_handleRebindInputs: function _handleRebindInputs() {
			this._unbindInput();
			this._bindInput();
		},
		_handleRowReset: function _handleRowReset() {
			this.reset();
		},
		_handleRowClear: function _handleRowClear() {
			this.clear();
		},
		_handleRowValidate: function _handleRowValidate() {
			this.validate();
		},
		_handleRowSetStatus: function _handleRowSetStatus(event, sStatus) {
			this.setStatus(sStatus);
		},
		_handleRowSetValidation: function _handleRowSetValidation() {
			$.noop();
		},
		_handleRowChangeLanguage: function _handleRowChangeLanguage(event, oldlanguage, newlanguage) {
			event.stopPropagation();
			if (oldlanguage && newlanguage) {
				this.changeLanguage(oldlanguage, newlanguage);
			}
		},
		_handleRowChangeLanguageDirection: function _handleRowChangeLanguageDirection(event, newlanguage) {
			event.stopPropagation();
			this._setLangDir(newlanguage);
		},
		_handleRowSendlabel: function _handleRowSendlabel() {
			$.noop();
		},
		_handleRowTranslationView: function _handleRowTranslationView(event, status, lang) {
			event.stopPropagation();
			if (lang !== undefined) {
				this._setTranslationView(status, lang);
			}
		},
		_handleFormTranslation: function _handleFormTranslation(event, handlers, fromWhere, lang) {
			if (this.options.multilanguage && lang !== undefined && this.language && this.formpage == this.oForm.currentFormPageId) {
				if (fromWhere === 'right') {
					handlers.push({
						scope: this,
						args: [lang, this.language]
					});
				} else if (fromWhere === 'left') {
					handlers.push({
						scope: this,
						args: [this.language, lang]
					});
				}
			}
		},
		_handleCanCopy: function _handleCanCopy(event, handlers) {
			var can = this.options.multilanguage && this._canCopyTranslation();
			if (can && this.formpage == this.oForm.currentFormPageId) {
				handlers.push(this);
			}
		},
		_handleRowTransferLanguage: function _handleRowTransferLanguage(event, sourceLang_ID, destLang_ID) {
			this.transferLanguage(sourceLang_ID, destLang_ID);
		},
		_handleRowAfterSubmit: function _handleRowAfterSubmit() {
			$.noop();
		},
		_handleRowCommunicate: function _handleRowCommunicate(event, data) {
			event.stopImmediatePropagation();
			if (data.setoption) {
				this.setStatus(data.value, null, null, data);
			}

		},
		_handleRemoveRow: function _handleRemoveRow(event, idx, ilang) {
			this._removeRow(idx, ilang);
		},

		// input handlers
		_handleInputChange: function _handleInputChange(event) {
			this._trigger("change", event);
			this.element.trigger("changerow.rowtype", event);
			this.validate();
		},
		_handleInputFocus: function _handleInputFocus(event) {
			this.element.trigger("focus.rowtype", event);
			$(event.currentTarget).parent().addClass('cs-rowtype-focus');
		},
		_handleInputFocusOut: function _handleInputFocusOut(event) {
			this.element.trigger("focusout.rowtype", event);
			$(event.currentTarget).parent().removeClass('cs-rowtype-focus');
		},

		_handleInputBlur: function _handleInputBlur() {
			$.noop();
		},
		_handleAfterLangInit: function _handleAfterLangInit() {
			$.noop();
		},
		_handleSetValueByField: function _handleSetValueByField(event, skeyVp, value) {
			if (this.initDone) {
				this._setValueByField(skeyVp, value);
			} else {
				this._addToQueue('initRowtype', this._setValueByField, this, [skeyVp, value]);
			}
		},

		/* custom functions */
		getAllInputs: function getAllInputs(idxProcess, iLangProcess, type) {
			return this._getAllInputs(idxProcess, iLangProcess, type);
		},

		highlight: function highlight(status) {
			if (status) {
				this.element.addClass(hoverClass);
			} else {
				this.element.removeClass(hoverClass);
			}
		},
		validate: function validate(idxProcess, iLangProcess, isSubmit, preventErrorOutput) {
			var ilang,
				aValidators = this._getValidators() || [],
				aErrors = [],
				oRes = 0,
				success = true,
				idx,
				iInitLangCount;

			idxProcess = idxProcess ? idxProcess : -1;
			iLangProcess = iLangProcess ? iLangProcess : -1;
			preventErrorOutput = preventErrorOutput ? preventErrorOutput : false;

			this.clearErrors(idxProcess, iLangProcess);

			// run the multiusage validator to check for min. one element
			if (this.options.multiusage && this.options.validation.required && !this.element.prop('disabled')) {
				iInitLangCount = this.options.initializedlanguages.length;
				/* only validate the initialized languages */
				for (idx = 0; idx < iInitLangCount; ++idx) {
					ilang = this.options.initializedlanguages[idx];

					if (this.oInputs.wrp[ilang] && (iLangProcess === -1 || iLangProcess === ilang)) {
						oRes = this.validator_run(this.multiusage.oActiveIndexes[ilang], ["multirequired"]);

						if (!oRes.success) {
							aErrors.push({
								jElWrp: this.oInputs.langWrp[ilang],
								ilang: ilang,
								idx: 0,
								sFormPage: this.formpage,
								msg: oRes.messages
							});
							success = false;
						}
					}
				}
			}

			if (!this.element.prop('disabled') && aValidators.length) {
				// run all validators for this rowtype
				$.eachCMSvalue(this.oInputs.inp, function(jEl, idx, ilang) {
					if ((iLangProcess === -1 || iLangProcess === ilang) && (idxProcess === -1 || idxProcess === idx) && $.inArray(ilang, this.options.initializedlanguages) >= 0) {
						jEl = jEl.is('input, textarea, select') ? jEl : jEl.find('input, textarea, select');

						if (jEl.prop('disabled')) {
							return;
						}
						oRes = this.validator_run(jEl, aValidators);
						if (((this.options.multiusage && $.inArray(idx, this.multiusage.oActiveIndexes[ilang]) >= 0) || !this.options.multiusage) && jEl.length && !oRes.success) {

							aErrors.push({
								jElWrp: jEl.parents('.ui-form-row-multi'),
								ilang: ilang,
								idx: idx,
								sFormPage: this.formpage,
								msg: oRes.messages
							});
							success = false;
						}
					}
				}, this);
			}

			if (!success && !preventErrorOutput) {
				this.showErrors(aErrors, isSubmit);
			} else {
				this.element.trigger("validateSuccess.rowtype", [isSubmit]);
			}
			return {
				success: success,
				errors: aErrors
			};
		},

		setStatus: function setStatus(sStatus, idxProcess, iLangProcess, data) {
			// sStatus = edit, disable, display; edit=show standard input, disable=standard input but no changes possible, display=text output only
			if (data && data.jEl) {
				var jEl = data.jEl,
					elLangwrp = jEl.closest('.ui-form-row-language');

				if (sStatus === "edit") {
					jEl.prop("disabled", false);
					this.element.prop("disabled", false);
					if (elLangwrp.hasClass('disabled')) {
						elLangwrp.removeClass('disabled');
					}
				} else if (sStatus === "disable") {
					jEl.prop("disabled", true);
					this.element.prop("disabled", true);
					elLangwrp.addClass('disabled');
				}
			} else {
				idxProcess = idxProcess ? idxProcess : -1;
				iLangProcess = iLangProcess ? iLangProcess : -1;
				$.eachCMSvalue(this.oInputs.inp, function(jEl, idx, ilang) {
					var elLangwrp;
					if ((idxProcess === -1 || idxProcess === idx) && (iLangProcess === -1 || iLangProcess === ilang)) {
						elLangwrp = jEl.closest('.ui-form-row-language');

						if (sStatus === "edit") {
							jEl.prop("disabled", false);
							this.element.prop("disabled", false);
							if (elLangwrp.hasClass('disabled')) {
								elLangwrp.removeClass('disabled');
							}
						} else if (sStatus === "disable") {
							jEl.prop("disabled", true);
							this.element.prop("disabled", true);
							elLangwrp.addClass('disabled');
						}
					}
				}, this);
			}

		},

		_setLangDir: function(langId, rowEl, langOverrideId) {
			var langEl = rowEl || langOverrideId !== undefined ? this.element.find('.ui-form-row-language[rel="' + langOverrideId + '"]') : this.element.find('.ui-form-row-language[rel="' + langId + '"]');
			if (langId instanceof Array && langId.length > 1) {
				langId.forEach(function(lang) {
					this._setLangDir(lang, this.element.find('.ui-form-row-language[rel="' + lang + '"]'));
				}.bind(this));
			} else {
				if (this.form.cForm('getLanguageDir', langId)) {
					langEl.addClass('con-lang-rtl');
				} else {
					langEl.removeClass('con-lang-rtl');
				}
			}
		},

		changeLanguage: function changeLanguage(oldlanguage, newlanguage) {
			if (this.options.multilanguage && oldlanguage !== newlanguage && ((!this.options.modificators || !this.options.modificators.init_hideLangWrp) || (this.options.modificators && this.options.modificators.init_hideLangWrp.apply(this, [oldlanguage, newlanguage])))) {
				// hide/show the languages
				if (this.oInputs.langWrp[oldlanguage] !== undefined && this.languageTranslation === null && this.multilangsOpen === false) {
					this.oInputs.langWrp[oldlanguage].hide();
				}

				// make sure that this languages has been initialized before switching languages
				if (this.options.initializedlanguages.indexOf(newlanguage) === -1) {
					this._initLanguage(newlanguage);
				}
				this.oInputs.langWrp[newlanguage].show();

				this.element.trigger('afterLanguageChanged.rowtype', [oldlanguage, newlanguage]);
				this.language = newlanguage;

				// only set the language direction if the language is actually changed
				this._setLangDir(newlanguage);
			}
		},

		get: function get(options) {
			var oReturn = {},
				sKeyMain;

			options = $.extend(true, {}, {
				asValuePath: true,
				onlyMain: false,
				asServerFormat: false,
				asLabel: false
			}, options);

			// simple version: only return the main data
			if (options.onlyMain) {
				$.eachCMSvalue(this.oInputs.inp, function(jEl, idx, ilang) {
					idx = parseInt(idx, 10);
					if (oReturn[ilang] === undefined) {
						oReturn[ilang] = [];
					}
					oReturn[ilang][idx] = this._getValue(jEl, options.asServerFormat);
				}, this);
			} else {
				// extended version: return main and attr data
				sKeyMain = (options.asValuePath ? this.options.valuepath : this.name);
				oReturn[sKeyMain] = {};
				// get main data
				$.eachCMSvalue(this.oInputs.inp, function(jEl, idx, ilang) {
					idx = parseInt(idx, 10);
					if (oReturn[sKeyMain][ilang] === undefined) {
						oReturn[sKeyMain][ilang] = [];
					}
					if (options.asLabel) {
						oReturn[sKeyMain][ilang][idx] = this._getLabel(jEl, options.asServerFormat);
					} else {
						oReturn[sKeyMain][ilang][idx] = this._getValue(jEl, options.asServerFormat);
					}
				}, this);

				$.eachCMSvalue(this.oInputs.attr, function(jEl, idx, ilang) {
					var sKeyAttr,
						oValSettings,
						sKeyFurther,
						oInp;

					for (sKeyAttr in this.initValueSettings) {
						// loop through all further-values and return them
						if (this.initValueSettings.hasOwnProperty(sKeyAttr)) {
							oValSettings = this.initValueSettings[sKeyAttr];
							sKeyFurther = options.asValuePath ? oValSettings.path : sKeyAttr;
							idx = parseInt(idx, 10);

							oInp = this._getInput(idx, ilang, 'attr', '.ui-form-row-input-attr-' + sKeyAttr);
							if (oReturn[sKeyFurther] === undefined) {
								oReturn[sKeyFurther] = {};
							}
							if (oReturn[sKeyFurther][ilang] === undefined) {
								oReturn[sKeyFurther][ilang] = [];
							}
							if (options.asLabel) {
								oReturn[sKeyFurther][ilang][idx] = this._getLabel(oInp, options.asServerFormat);
							} else {
								oReturn[sKeyFurther][ilang][idx] = this._getValue(oInp, options.asServerFormat);
							}
						}
					}
				}, this);
			}

			return oReturn;
		},

		getInputElement: function getInputElement(index, language, type) {
			var sType,
				selector;

			sType = ((type === undefined || type === 'main' || this.initValueSettings[type] !== undefined) ? 'inp' : 'attr');
			selector = this.initValueSettings[type] === undefined ? null : type;

			if (this.options.multilanguage) {
				language = language ? language : this.language;
			} else {
				language = '0';
			}
			return this._getInput(index || 0, language, sType, (selector ? '.ui-form-row-input-attr-' + selector : null));
		},

		set: function set(oData, datakey, bSetInit, opt) {
			bSetInit = (bSetInit !== undefined ? bSetInit : true);
			datakey = (!!datakey && datakey.length ? datakey : null);

			// try to find the right data
			if (datakey !== null && oData[datakey] !== undefined) {
				oData = oData[datakey];
			} else if (datakey === null) {
				datakey = 'main';
				if (oData[datakey]) {
					oData = oData[datakey];
				}
			}
			if (oData) {
				// loop through the data and set/add the elements
				$.eachCMSvalue(oData, function(oVal, idx, ilang) {

					if ((ilang === 0 && !this.options.multilanguage) || $.inArray(ilang, this.languages) >= 0) {
						// if multiusage add a new row
						if (datakey === 'main' && this.options.multiusage) {

							if (this.options.multiusage && this.options.multiusagesettings.fnAddCondition.apply(this, [ilang, idx, oVal])) {
								this.addRow(ilang, oVal, true);
							}
						}
						if (this.options.multiusage || (!this.options.multiusage && idx === 0)) {
							// find the input element
							var oInp = this._getInput(idx, ilang, datakey === 'main' ? 'inp' : 'attr', datakey === 'main' ? null : '.ui-form-row-input-attr-' + datakey);
							// call _setValue for every single lang/index element

							this._setValue(oInp, oVal, datakey, $.extend(true, {}, opt, {
								ilang: ilang,
								idx: idx
							}));
							if (bSetInit) {
								// put the data back to the form-Init data ( cForm.options.data )
								this._setInitValue(oVal, datakey, ilang, idx);
							}
						}
					}
				}, this);
			}
		},
		setLangValue: function setLangValue(datakey, ilang) {
			var oLangData,
				oInp,
				idx;

			oLangData = this.initValue[datakey][ilang];
			if (oLangData) {
				for (idx = 0; idx < oLangData.length; ++idx) {
					if (oLangData[idx] !== null) {
						if ((ilang === 0 && !this.options.multilanguage) || $.inArray(ilang, _.union(this.languages, this.contentLanguages)) >= 0) {
							// if multiusage add a new row
							if (datakey === 'main' && this.options.multiusage) {
								if (this.options.multiusage && this.options.multiusagesettings.fnAddCondition.apply(this, [ilang, idx, oLangData[idx]])) {
									this.addRow(ilang, oLangData[idx], true);
								}
							}
							if (this.options.multiusage || (!this.options.multiusage && idx === 0)) {
								// find the input element
								oInp = this._getInput(idx, ilang, datakey === 'main' ? 'inp' : 'attr', datakey === 'main' ? null : '.ui-form-row-input-attr-' + datakey);
								// call _setValue for every single lang/index element
								this._setValue(oInp, oLangData[idx], datakey, {
									ilang: ilang,
									idx: idx
								});
							}
						}
					}
				}
			}
		},
		reset: function reset() {
			var sKey;

			this.set(this.initValue, 'main');
			// reset the data to the data placed in the cForm
			for (sKey in this.initValueSettings) {
				if (this.initValueSettings.hasOwnProperty(sKey)) {
					this.set(this.initValue[sKey], sKey);
				}
			}
		},

		clear: function clear() {
			$.noop();
		},

		focus: function focus(index, language) {
			if (this.options.multilanguage) {
				language = language ? language : this.language;
			} else {
				language = '0';
			}
			return this._getInput(index || 0, language, 'inp').focus();
		},

		transferLanguage: function transferLanguage(sourceLang_ID, destLang_ID) {
			var idx,
				iLength,
				oIdxCont = null;

			// loop through all inputs and transfer values
			iLength = this.oInputs.inp.length;
			for (idx = 0; idx < iLength; ++idx) {
				if (oIdxCont === this._getInput(idx, sourceLang_ID, 'wrp')) {
					this._setValue(this._getInput(idx, destLang_ID, 'inp'), this._getValue(oIdxCont));
				}
			}
		},

		generateErrorMessage: function generateErrorMessage(oData) {
			var oMsg,
				oShowError;

			this._rowServerResponseErrorHandling(oData); // overwritten in some rowtypes
			oMsg = {};

			if (oData.message && oData.message.length) {
				oMsg[oData.type] = oData.message;
			} else {
				oMsg[oData.type] = this.validator_getMsg(oData.type, oData.data);
			}
			oShowError = {
				jElWrp: oData.jEl,
				idx: parseInt(oData.idx, 10),
				ilang: parseInt(oData.ilang, 10),
				msg: oMsg
			};
			this.showErrors([oShowError], true);
		},

		showErrors: function showErrors(aErrors, isSubmit) {
			var idxErr,
				iErrorCount,
				oElm;

			iErrorCount = aErrors.length;

			for (idxErr = 0; idxErr < iErrorCount; ++idxErr) {
				if (aErrors[idxErr].jElWrp && aErrors[idxErr].idx === 0) {
					oElm = aErrors[idxErr].jElWrp;
				} else {
					oElm = this._getInput(aErrors[idxErr].idx, aErrors[idxErr].ilang, 'wrp');
					if (!oElm || (oElm && oElm.length === 0)) {
						oElm = this._getInput(aErrors[idxErr].idx, aErrors[idxErr].ilang, 'langWrp');
					}
					if ((!oElm || (oElm && oElm.length === 0)) && aErrors[idxErr].jElWrp) {
						oElm = aErrors[idxErr].jElWrp;
					}
				}

				this.element.trigger("validationError.rowtype", [oElm]);
				this.showError(oElm, aErrors[idxErr]);
			}
			/* trigger error */
			this.element.trigger("validateError.rowtype", [isSubmit]);
		},

		showError: function showError(elWrp, oErrors) {
			var elError, oError;

			this.element.addClass(errorClass);

			if (elWrp.length) {
				elWrp.find('.ui-form-row-input').addClass(errorClass);
				elError = elWrp.find('.ui-form-error').first();
				elError.empty();
				for (oError in oErrors.msg) {
					if (oErrors.msg.hasOwnProperty(oError)) {
						if (oErrors.msg[oError].length) {
							elError.addClass('cs-top-error').html(oErrors.msg[oError]).show();
						}
					}
				}
			} else {
				for (oError in oErrors.msg) {
					if (oErrors.msg.hasOwnProperty(oError)) {
						if (oErrors.msg[oError].length) {
							this.oForm.showMessage({
								"content": this.element.find('.ui-form-row-label').text() + ": " + oErrors.msg[oError],
								"type": "error"
							});
						}
					}
				}
			}
		},

		clearErrors: function clearErrors(idxProcess, iLangProcess) {
			var oInputs = this._getAllErrorWrappers(idxProcess, iLangProcess, 'wrp');
			this.element.removeClass(errorClass);
			oInputs.find('.ui-form-row').removeClass(errorClass);
			oInputs.find('.ui-form-row-input').removeClass(errorClass);
			oInputs.find('.ui-form-error').empty().hide().removeClass('cs-top-error');
			oInputs.closest('.ui-form-row').removeClass(errorClass);
			oInputs.closest('.ui-form-row').find('.ui-form-row-input').removeClass(errorClass);
			oInputs.closest('.ui-form-row').find('.ui-form-error').empty().hide().removeClass('cs-top-error');
		},

		getLabels: function getLabels() {
			var aLabels = {},
				ilang = 0,
				idx = 0,
				iLength,
				oInputEls = this.oInputs.inp;

			for (ilang in oInputEls) {
				if (oInputEls.hasOwnProperty(ilang)) {
					aLabels[ilang] = [];
					iLength = oInputEls[ilang].length;
					for (idx = 0; idx < iLength; ++idx) {
						aLabels[ilang][idx] = this._getLabel(oInputEls[ilang][idx]);
					}
				}
			}
			return aLabels;
		},

		getValues: function getValues() {
			var aValues = {},
				ilang = 0,
				idx = 0,
				iLength,
				oInputEls = this.oInputs.inp;

			for (ilang in oInputEls) {
				if (oInputEls.hasOwnProperty(ilang)) {
					aValues[ilang] = [];
					iLength = oInputEls[ilang].length;
					if (this.multiusage && this.multiusage.oActiveIndexes) {
						for (idx = 0; idx < this.multiusage.oActiveIndexes[ilang].length; ++idx) {
							aValues[ilang][idx] = this._getValue(oInputEls[ilang][this.multiusage.oActiveIndexes[ilang][idx]]);
						}
					} else {
						for (idx = 0; idx < iLength; ++idx) {
							aValues[ilang][idx] = this._getValue(oInputEls[ilang][idx]);
						}
					}
				}
			}
			return aValues;
		},

		addWidgetReference: function addWidgetReference(key, value, ilang) {
			this.widgetStoredReferences[ilang] = {};
			this.widgetStoredReferences[ilang][key] = value;
		},

		getWidgetReference: function getWidgetReference(key, ilang) {
			if (this.widgetStoredReferences[ilang] && this.widgetStoredReferences[ilang][key]) {
				return this.widgetStoredReferences[ilang][key];
			}
			return null;
		},
		onSubmit: function onSubmit() {
			var bReturn = true;
			if (typeof this.options.onSubmit === 'function') {
				bReturn = this.options.onSubmit.apply(this);
			}
			return bReturn;
		},
		setRequired: function setRequired(bRequired) {
			if (bRequired || bRequired === undefined) {
				this.element.addClass('required');
				this.options.validation.required = true;
			} else {
				this.element.removeClass('required');
				this.options.validation.required = false;
			}
		},
		/* internal custom functions */
		_getForm: function _getForm() {
			return this.element.parents('form');
		},

		_getCurrentLangWrp: function _getCurrentLangWrp() {
			if (this.options.multilanguage) {
				return this.oInputs.langWrp[this.language];
			}
			return this.oInputs.langWrp[0];
		},

		_initElement: function _initElement(jEl) {
			jEl.trigger('beforeInitElement.rowtype', arguments);
			jEl.trigger('afterInitElement.rowtype', arguments);
		},

		_getMainInputs: function _getMainInputs(idxProcess, iLangProcess) {
			var ajLang,
				ajMulti,
				oInputs = {},
				oInputWrps = {},
				oInputLangWrps = {},
				oInputsAttr;

			if (this.options.setup.hasAttributeInputs) {
				oInputsAttr = {};
			}

			// get all lang-wrappers to loop through.
			if (this.options.modificators && this.options.modificators.getMainInputs_lang) {
				ajLang = this.options.modificators.getMainInputs_lang.apply(this, [iLangProcess]);
			} else {
				ajLang = this.element.find('.' + lang_wrp);
			}

			idxProcess = idxProcess ? parseInt(idxProcess, 10) : -1;
			iLangProcess = iLangProcess ? parseInt(iLangProcess, 10) : -1;
			// loop over all found languages
			ajLang.each($.proxy(function(idx, elLang) {
				var jElLang,
					iLang;

				jElLang = $(elLang);

				// get the lang_id by interpreting the rel-attr
				iLang = parseInt(elLang.getAttribute('rel'), 10);

				// only loop through the defined languages or on -1 to all languages
				if (iLangProcess === -1 || iLangProcess === iLang) {
					// find all multiusage elements
					if (this.options.modificators && this.options.modificators.getMainInputs_index) {
						ajMulti = this.options.modificators.getMainInputs_index.apply(this, [jElLang, iLang, iLangProcess]);
					} else {
						ajMulti = jElLang.find('.' + multi_wrp);
					}

					oInputs[iLang] = [];
					oInputWrps[iLang] = [];
					if (this.options.setup.hasAttributeInputs) {
						oInputsAttr[iLang] = [];
					}
					// loop through all found multiusage elements
					ajMulti.each($.proxy(function(idx, elMulti) {
						var jElMulti,
							iMulti,
							ajInput,
							ajInputAttr;

						if ((idxProcess === -1 || idxProcess === idx)) {
							jElMulti = $(elMulti);
							ajInput = jElMulti.find('.' + mainInput);
							// get the index by interpreting the id of the inputfield (main input exists)
							if (ajInput[0] !== undefined && ajInput.attr('id')) {
								iMulti = parseInt(ajInput.attr('id').split('-')[2], 10) - 1;
								// get the index by interpreting the rel-attr
							} else {
								iMulti = parseInt(jElMulti.attr('rel'), 10) - 1;
							}
							// add the index and language to the multiusage element for later usage
							$.data(elMulti, 'rowtype-element', {
								ilang: iLang,
								idx: iMulti
							});
							ajInput.data('rowtype-element', {
								ilang: iLang,
								idx: iMulti
							});
							// put the elements into the correct structure
							oInputs[iLang][iMulti] = ajInput;
							oInputWrps[iLang][iMulti] = jElMulti;
							// try to find attributes in between the element
							if (this.options.setup.hasAttributeInputs) {
								ajInputAttr = jElMulti.find('.' + attrInput);
								oInputsAttr[iLang][iMulti] = ajInputAttr;
							}
						}
					}, this));

					// add the langwrapper
					oInputLangWrps[iLang] = jElLang;
				}
			}, this));
			// extend the local cache object
			$.extend(true, this.oInputs.inp, oInputs);
			$.extend(true, this.oInputs.wrp, oInputWrps);
			$.extend(true, this.oInputs.langWrp, oInputLangWrps);
			if (this.options.setup.hasAttributeInputs) {
				$.extend(true, this.oInputs.attr, oInputsAttr);
			}
			this.oInputs.mainWrp = this.element.find('.ui-form-row-languagewrp');
		},
		// Override this method if the rowtype does not show errors for each individual input.
		_getAllErrorWrappers: function _getAllErrorWrappers(idxProcess, iLangProcess, type) {
			return this._getAllInputs(idxProcess, iLangProcess, type);
		},
		_getAllInputs: function _getAllInputs(idxProcess, iLangProcess, type) {
			var aReturn = [];
			type = type === undefined ? 'inp' : type;
			idxProcess = idxProcess ? idxProcess : -1;
			iLangProcess = iLangProcess ? iLangProcess : -1;
			$.eachCMSvalue(this.oInputs[type], function(jEl, idx, ilang) {
				if ((idxProcess === -1 || idxProcess === idx) && (iLangProcess === -1 || iLangProcess === ilang)) {
					jEl.each($.proxy(function(idx, el) {
						if (['inp', 'wrp'].indexOf(type) > -1) {
							if (jEl.data('rowtype-element')) {
								aReturn.push(el);
							}
						} else {
							aReturn.push(el);
						}
					}, this));
				}
			}, this);
			return $(aReturn);
		},

		_setValueByField: function _setValueByField(skeyVp, value) {
			var langkey, usagekey, doInitvalue = true;

			// set data
			if (this.options.modificators.getValues_lang) {
				this.initValue[skeyVp] = this.options.modificators.getValues_lang.apply(this, [skeyVp, value]);
			} else {
				this.initValue[skeyVp] = value;
			}

			if (this.isAdminForm && this.options.setup.ismultipleusage === 1) {
				for (langkey in this.initValue[skeyVp]) {
					for (usagekey in this.initValue[skeyVp][langkey]) {
						if (!this.initValue[skeyVp][langkey][usagekey].value.length) {
							doInitvalue = false;
							break;
						}
					}
				}
			}

			if (this.initValue[skeyVp] !== null && doInitvalue) {
				// convert server value to local value
				$.eachCMSvalue(this.initValue[skeyVp], function(val, idx, ilang) {
					if (!isNaN(ilang)) {
						this.initValue[skeyVp][ilang][idx] = this._extendServerValue(val, skeyVp);
					}
				}, this);

				--this.expectedValueTypesCount;
			}
		},
		_initLanguage: function _initLanguage(lang) {
			/* lang can either be a single language or an array of languages that are to be initialized */
			var aLangs = [],
				idx,
				expectedValuesLength,
				idy,
				el,
				dirlang = lang,
				oForm;

			if (typeof lang === 'number') {
				aLangs.push(lang);
			} else {
				aLangs = lang;
			}

			if (this.form.data('cms-cForm')) {
				oForm = $.data(this.form[0], 'cms-cForm');
			}

			// if custom code has set the form's default_langid, then use this to determine if we should use RTL or not
			if (oForm && oForm.options.directionLanguage) {
				dirlang = parseInt(oForm.options.directionLanguage, 10);
			}

			this._addLanguageMarkup(aLangs);
			if (dirlang instanceof Array && dirlang.length === 1) {
				dirlang = dirlang[0];
			}
			if (dirlang !== 0) {
				// loop over the inputs for the newly initialized language and set the langdirecton for the elements
				if (this.language === 0) {
					// pass the langOverrideId - this forces single-language elements to display in either RTL or LTR
					this._setLangDir(dirlang, undefined, 0);
				} else {
					this._setLangDir(dirlang);
				}
			}
			this._initRowElements(aLangs);

			if (this.expectedValueTypesCount <= 0) {
				expectedValuesLength = this.expectedValueTypes.length;
				for (idx = 0; idx < expectedValuesLength; ++idx) {
					for (idy = 0; idy < aLangs.length; ++idy) {
						this.setLangValue(this.expectedValueTypes[idx], aLangs[idy]);
						this.element.trigger('afterLangInit.rowtype', [aLangs[idy]]);
						if (this.options.validation.required === true) {
							this._getAllInputs(-1, this.language).each(function() {
								el = $(this);
								el.trigger('languageInitialized.rowtype', el, aLangs[idy]);
							});
						}
						if (this.options.isDisabled) {
							this.setStatus('disable', idx, idy);
						}
					}
				}
			}

		},
		_initRowElements: function _initRowElements(aLangs) {
			var elLangWrp,
				ilang = 0,
				idx = 0,
				ilanglen = aLangs.length;

			for (ilang = 0; ilang < ilanglen; ++ilang) {
				elLangWrp = this.oInputs.wrp[aLangs[ilang]];
				if (elLangWrp) {
					for (idx = 0; idx < elLangWrp.length; ++idx) {
						this._initElement(elLangWrp[idx], idx, aLangs[ilang]);
					}
				}
			}
		},
		_addLanguageMarkup: function _addLanguageMarkup(aLangs) {
			var elMainWrp = this.oInputs.mainWrp,
				elLangWrp,
				ilang = 0,
				ilanglen = aLangs.length,
				oData = {},
				oLangLabelEl;

			for (ilang = 0; ilang < ilanglen; ++ilang) {

				/* sets the rows value for specified language */
				if (this.options.initializedlanguages.indexOf(aLangs[ilang]) === -1) {
					this.options.initializedlanguages.push(aLangs[ilang]);
				}

				if (aLangs[ilang] > 0) { // 0 lang is always available
					elLangWrp = this.oInputs.langWrp[aLangs[ilang]];

					if (!elLangWrp) {
						oData.langid = aLangs[ilang];
						oData.rowclassname = 'ui-form-row-language';
						$.tmpl(this.multilanguage.sModel, oData).appendTo(elMainWrp);
						this._getMainInputs(-1, aLangs[ilang]);
						this.element.trigger('htmlMarkupAdded.rowtype', aLangs[ilang]);
						elLangWrp = this.oInputs.langWrp[aLangs[ilang]];
					}

					if (this.showMultilangs === true) {
						oLangLabelEl = $('<div class="ui-form-row-textline-language">' + this.form.cForm('option', 'languagemapping')[aLangs[ilang]] + "</div>");
						elLangWrp.prepend(oLangLabelEl);
					}
				}
			}
		},
		_extendServerValue: function _extendServerValue(value) {
			if (this.isValueUppercase) {
				return value.VALUE;
			}

			if (typeof value === "object") {
				if (value.hasOwnProperty('value')) {
					return value.value;
				}
				return "";
			}
			return value;
		},
		_convertToServerValue: function _convertToServerValue(value) {
			if (this.isValueUppercase) {
				return {
					VALUE: value
				};
			}
			return {
				value: value
			};
		},
		_bindInput: function _bindInput(idxProcess, iLangProcess) {
			/* dont delegate if idxprocess and ilangprocess are defined */
			if (idxProcess === undefined && iLangProcess === undefined) {
				this.element.on("change", ".ui-form-row-input-main", $.proxy(this._handleInputChange, this));
				this.element.on("blur", ".ui-form-row-input-main", $.proxy(this._handleInputBlur, this));
				this.element.on("focusin", ".ui-form-row-input-main", $.proxy(this._handleInputFocus, this));
				this.element.on("focusout", ".ui-form-row-input-main", $.proxy(this._handleInputFocusOut, this));
			}
			this._overrideEvents();
		},

		_unbindInput: function _unbindInput() {
			this.element.off("change", ".ui-form-row-input-main", $.proxy(this._handleInputChange, this));
			this.element.off("blur", ".ui-form-row-input-main", $.proxy(this._handleInputBlur, this));
			this.element.off("focusin", ".ui-form-row-input-main", $.proxy(this._handleInputFocus, this));
			this.element.off("focusout", ".ui-form-row-input-main", $.proxy(this._handleInputFocusOut, this));
		},

		_overrideEvents: function _overrideEvents() {
			var overrideEvent, customEvents, formName;

			if (this.form.data('cms-cForm')) {
				formName = this.form.cForm('option', 'name');
				if (window.cms.oSettings && window.cms.oSettings.javascript.customevents &&
					window.cms.oSettings.javascript.customevents[formName] &&
					window.cms.oSettings.javascript.customevents[formName][this.name]) {

					customEvents = window.cms.oSettings.javascript.customevents[formName][this.name];

					for (overrideEvent in customEvents) {
						if (customEvents.hasOwnProperty(overrideEvent)) {
							this.options.setup[overrideEvent] = customEvents[overrideEvent];
						}
					}
				}
			}
		},

		_checkMultiAddSubForm: function _checkMultiAddSubForm() {
			return true;
		},

		_getInput: function _getInput(index, language, type, selector) {
			if (type === "langWrp") {
				try {
					return $(this.oInputs[type][language]);
				} catch (e) {
					return null;
				}
			} else {
				try {
					selector = selector !== undefined ? selector : null;
					if (selector !== null && selector.length) {
						return $(this.oInputs[type][language][index].filter(selector));
					}
					return $(this.oInputs[type][language][index]);
				} catch (e2) {
					return null;
				}
			}
		},

		_setValue: function _setValue(jEl, value) {
			if (jEl) {
				jEl.val(value);
			}
			return false;
		},

		_clearValue: function _clearValue(jEl) {
			if (jEl) {
				jEl.val('');
			}
			return false;
		},

		_getValue: function _getValue(jEl, asServerFormat) {
			var value = jEl.val();
			return asServerFormat ? this._convertToServerValue(value) : value;
		},

		_getLabel: function _getLabel(jEl, asServerFormat) {
			var value = jEl.val();
			return asServerFormat ? this._convertToServerValue(value) : value;
		},

		_getInitValue: function _getInitValue(datakey, ilang, idx) {
			var oData = this.initValue[datakey],
				settings;

			if (this.initValue && oData) {
				settings = $.extend({
					multilanguage: this.options.multilanguage,
					multiusage: this.options.multiusage
				}, this.initValueSettings[datakey]);

				ilang = settings.multilanguage ? ilang : 0;
				idx = settings.multiusage ? idx : 0;

				if (oData && oData[ilang] && [idx]) {
					return oData[ilang][idx];
				}
				return {};
			}
			return null;
		},
		_setInitValue: function _setInitValue(value, datakey, ilang, idx) {
			var oValSettings;

			if (datakey === 'main') {
				try {
					this.initValue[datakey][ilang][idx] = value;
				} catch (e) {
					$.noop();
				}
				this.form.cForm('setValueByField', this.options.valuepath, this.initValue.main);
			} else {
				oValSettings = this.initValueSettings[datakey];
				try {
					this.initValue[datakey][(oValSettings.multilanguage ? ilang : 0)][(oValSettings.multiusage ? idx : 0)] = value;
				} catch (e) {
					$.noop();
				}
				this.form.cForm('setValueByField', oValSettings.path, this.initValue[datakey]);
			}
		},

		_getValidators: function _getValidators() {
			var aValidators = [];
			return aValidators;
		},
		_getLangIdxByName: function _getLangIdxByName(name) {
			var aMatch = name.match(/-\d-\d/g),
				aElments;

			if (aMatch.length) {
				aElments = aMatch[0].split('-');
				if (aElments.length >= 3) {
					return {
						idx: aElments.slice(1)[1],
						lang: aElments.slice(1)[0]
					};
				}
			}
			return {};
		},

		_removeRow: function _removeRow(idx, ilang) {
			var oWrp,
				oInp;

			oWrp = this._getInput(idx, ilang, 'wrp');
			oInp = this._getInput(idx, ilang, 'inp');

			if (oWrp) {
				oWrp.remove();
			}
			if (oInp) {
				oInp.remove();
			}
		},
		_rowServerResponseErrorHandling: function _rowServerResponseErrorHandling() {
			$.noop();
		},
		_transferValues: function _transferValues(oData) {
			this.set(oData);
		},
		/**
		 * Parse and validate value for left-right copy before passing it to _setValue.
		 * To cancel operation, return undefined
		 *
		 * @param val - text/object to be copied
		 * @returns {*}
		 * @private
		 */
		_copyTranslationParseValue: function _copyTranslationParseValue(val) {
			return val;
		},
		/**
		 * Translate text via API.
		 *
		 * @param val - text/object to be copied
		 * @param dstLangId - Language ID of destination language
		 * @param srcLangId - Language ID of source language = language of "val"
		 * @param callback - Callback function to return translated value.
		 * @returns {*}
		 * @private
		 */
		_translateText: function _translateText(val, dstLangId, srcLangId, callback) {
			var dstLangISO, srcLangISO;
			if (val && window.cms.oSettings.objects.translateOnCopy && typeof val === "string") {

				// determine iso codes for translation
				dstLangISO = this.form.cForm('getLanguageIso', dstLangId);
				srcLangISO = this.form.cForm('getLanguageIso', srcLangId);
				$.contensAPI('translate.getTranslation', {
					text: encodeURIComponent(val),
					source: srcLangISO,
					target: dstLangISO
				}, (resp) => {
					if (typeof callback === "function") {
						callback(resp);
					}
				});
			} else {
				if (typeof callback === "function") {
					callback(val);
				}
			}
		},
		/**
		 * Flag used to determine if auto translation should be used.
		 */
		_canAutoTranslate: function _canAutoTranslate() {
			return false;
		},
		/**
		 * Translate value for left-right copy before passing it to _copyTranslationParseValue.
		 * Basically wraps _translateText to help subclassing.
		 *
		 * @param val - text/object to be copied
		 * @param dstLangId - Language ID of destination language
		 * @param srcLangId - Language ID of source language = language of "val"
		 * @param callback - Callback function to return translated value.
		 * @returns {*}
		 * @private
		 */
		_translateValue: function _translateValue(val, dstLangId, srcLangId, callback) {
			if (this._canAutoTranslate()) {
				this._translateText(val, dstLangId, srcLangId, callback);
			} else {
				if (typeof callback === "function") {
					callback(val);
				}
			}
		},
		/**
		 * Used by _copyTranslation to obtain the  javascript type/object that can be passed to _setValue.
		 * To cancel the _copyTranslation operation, return undefined.
		 *
		 * @param jEl
		 * @param asServerFormat
		 * @returns {*}
		 * @private
		 */
		_getValueObject: function _getValueObject(jEl, asServerFormat) {
			return this._getValue(jEl, asServerFormat);
		},
		_setCopyValue: function _setCopyValue(newVal) {
			return newVal;
		},
		_canCopySrcElement: function _canCopySrcElement(srcElement) {
			return !this.options.multiusage || (this.options.multiusage && srcElement.closest("li").is(":visible")) ||
				(this.options.multiusage && srcElement.closest("tr.ui-form-row-multi").is(":visible"));
		},
		_copyTranslation: function _copyTranslation(srcLangId, destLangId, callback) {
			var srcval,
				subid,
				srcElement,
				destElement,
				datakey = 'main',
				elLi,
				newVal,
				opt = {},
				next;

			opt.ilang = destLangId;

			if (this.options.multiusage) {
				destElement = this._getAllInputs(-1, destLangId, 'wrp');
				$.each(destElement, $.proxy(function(idx, el) {
					var elm = $(el);
					this.removeRow(elm, elm.data('multiusage').idx, destLangId);
				}, this));
			}
			if (!this._getForm().cForm('translatingAll')) {
				this._getForm().cForm('showLoading', true);
			}
			// now needs to be async aware
			subid = -1;
			next = () => {
				subid++;
				if (subid < this.oInputs['inp'][srcLangId].length) {
					opt.idx = subid;
					srcElement = this._getInput(subid, srcLangId, 'inp');

					if (this._canCopySrcElement(srcElement)) {

						srcval = this._getValueObject(srcElement);

						if (srcval !== undefined) {
							this._translateValue(srcval, destLangId, srcLangId, $.proxy(function(translated) {
								newVal = this._copyTranslationParseValue(translated, destLangId);
								destElement = this._getInput(subid, destLangId, 'inp');
								if (this.options.multiusage) {
									elLi = this.addRow(destLangId, this._setCopyValue(srcval), true);
									opt.idx = elLi.attr('rel') - 1;
									destElement = this._getInput(opt.idx, destLangId, 'inp');
								}
								if (newVal !== undefined) {
									this._setValue(destElement, this._setCopyValue(newVal), datakey, opt);
									destElement.trigger("change");
									this._copyTranslationAfter(destElement, opt.idx, destLangId);
								}
								next();
							}, this));
						} else {
							next();
						}
					} else {
						next();
					}
				} else {
					if (typeof callback == "function") {
						callback();
					}
					if (!this._getForm().cForm('translatingAll')) {
						this._getForm().cForm('showLoading', false);
					}
				}
			};
			next();
		},

		_canCopyTranslation: function _canCopyTranslation() {
			return false;
		},
		_copyTranslationAfter: function _copyTranslationAfter() {
			return false;
		},
		_setTranslationView: function _setTranslationView(status, ilangTrans) {
			var tmpLanguageTranslation = null,
				self = this,
				oLastLangDiv, sButtonsFloat = "left",
				languageWrp,
				bTranslate = (window.cms.oSettings.objects.translateEngine.length && window.cms.oSettings.objects.translateOnCopy);

			// check if passed language is already activated and if it's not the left-side ( main ) language
			if (this.options.multilanguage && (!status || (ilangTrans !== this.language && ilangTrans !== this.languageTranslation))) {
				status = status !== undefined ? status : false;

				if (status) {
					if (this.options.initializedlanguages.indexOf(ilangTrans) === -1) {
						this._initLanguage(ilangTrans);
					}

					if (this.transicon !== undefined) {
						if (this.multilangsOpen === true) {
							this._toggleMultilangs();
						}
						this.transicon.hide();
					}

					// remove old transfer buttons
					this.element.find('.js-removeTrnsferButton').remove();

					this.element.find('.ui-form-row-language-transright').removeClass('ui-form-row-language-transright');

					// activate translation view
					$.each(this.oInputs.langWrp, $.proxy(function(ilang, el) {

						ilang = parseInt(ilang, 10);

						// if another language is activated as translation hide it
						if (ilang === this.languageTranslation) {
							$(el).removeClass('ui-form-row-language-transright').hide();
						}

						// add class to left side language (main) to place languages side by side
						if (ilang === this.language) {
							$(el).addClass('ui-form-row-language-transleft');
							if (this.form.cForm('getLanguageDir', ilang)) {
								this.element.removeClass('con-lang-rtl');
								$(el).addClass('con-lang-rtl');
							}
						}

						// add class to translation language to place languages side by side
						if (ilang === ilangTrans) {
							$(el)
								.show()
								.addClass('ui-form-row-language-transright');

							if (this.form.cForm('getLanguageDir', ilangTrans)) {
								$(el).addClass('con-lang-rtl');
							} else {
								$(el).removeClass('con-lang-rtl');
							}

							$(languageWrp).removeClass('con-transfer-reverse');

							this._transButton = '<div class="js-removeTrnsferButton cs-removeTrnsferButton">';

							if (this._canCopyTranslation(this.element) === true) {
								this._transButton +=
									'<button type="button" class="con-button con-button-no-ds sys-addtip langTrnsferButtonLeft" original-title="' + (bTranslate ? window.cms.i18n.system.text.translate : window.cms.i18n.system.text.copy) + '"><div class="con-icon con-icon-arrow-left"></div></button>' +
									'<button type="button" class="con-button con-button-no-ds sys-addtip langTrnsferButtonRight" original-title="' + (bTranslate ? window.cms.i18n.system.text.translate : window.cms.i18n.system.text.copy) + '"><div class="con-icon con-icon-arrow-right"></div></button>';
							}

							this._transButton += '</div>';
							this._transButton = $(this._transButton);

							if (this._canCopyTranslation(this.element) === true) {
								this._transButton.find('.langTrnsferButtonLeft').on('click', function() {
									self._copyTranslation(ilangTrans, self.language);
								});

								this._transButton.find('.langTrnsferButtonRight').on('click', function() {
									self._copyTranslation(self.language, ilangTrans);
								});
							}

							// save new translation language
							tmpLanguageTranslation = ilangTrans;
						}
					}, this));

					// The best place to calculate button positions
					oLastLangDiv = $(this.oInputs.mainWrp[0]).children().filter(function() {
						return $(this).css("display") === "block";
					}).last();

					if (oLastLangDiv.prop("class").indexOf("ui-form-row-language-transright") === -1) {
						sButtonsFloat = "right";
					}
					languageWrp = this.element.find('.ui-form-row-languagewrp');
					if (sButtonsFloat === 'right') {
						$(languageWrp).addClass('con-transfer-reverse');
					} else {
						$(languageWrp).removeClass('con-transfer-reverse');
					}

					oLastLangDiv.before(this._transButton);

					// save new translation language
					if (tmpLanguageTranslation) {
						this.languageTranslation = tmpLanguageTranslation;
					}

					this.element.trigger('translationviewActivated.rowtype', [ilangTrans]);

				} else {
					// deactivate translation view
					// remove all translation-view classes

					if (this.transicon !== undefined) {
						this.transicon.show();
					}

					this.element.find('.js-removeTrnsferButton').remove();
					this.element.find('.ui-form-row-languagewrp').removeClass('con-transfer-reverse');

					this.element.find('.ui-form-row-language-transleft')
						.removeClass('ui-form-row-language-transleft');
					// hide the right-side language
					this.element.find('.ui-form-row-language-transright')
						.hide()
						.removeClass('ui-form-row-language-transright');

					this.element.trigger('translationviewDeactivated.rowtype', [this.languageTranslation]);
					this.languageTranslation = null;
				}
				this.element.trigger('translationviewChanged.rowtype', [status, ilangTrans]);
			}
		},
		_setReadOnly: function _setReadOnly() {
			/* TODO: Implement readonly feature in each rowtype */
			this.element.addClass('cs-readonly');
		}
	});

	$.extend($.cms.cRowtype, {
		version: "1.0"
	});

}(jQuery, window));
